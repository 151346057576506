import React from "react";
import videojs from 'video.js';

class VideoPlayer extends React.Component {
  componentDidMount() {    
    this.player = videojs(this.videoNode, this.props);
    this.player.responsive(true)
  }
  
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
  
  render() {  
    return (
      <video
        autoPlay
        className="video-js vjs-4-3"
        ref={(c) => { this.videoNode = c; }}
      ></video>
    );
  }
}

export default VideoPlayer;