import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import VideoPlayer from "./VideoPlayer";

class Player extends Component {

  render() {
    const { open, toggleModal, url } = this.props;
    const videoJsOptions = {
      autoplay: false,
      controls: false,
      currentTime: 0,
      responsive: true,
      autoPictureInPicture: true,
      sources: [{
        src: url,
      }]
    }
   
    return (
      <Modal
        isOpen={open}
        toggle={toggleModal}
        className={"modal-info modal-lg"}
        centered={true}
      >
        <VideoPlayer { ...videoJsOptions } />
      </Modal>
    );
  }
}

export default Player;

