/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Player from "./Player";
import Mail from "nodemailer/lib/mailer";

function LandingPage() {
  const [videoOpen, setVideoOpen] = useState(false);
  const [fullnameContact, setFullnameContact] = useState('');
  const [emailContact, setEmailContact] = useState('');
  const [messageContact, setMessageContact] = useState('');

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  const enviarEmail = () => {
    const {
      REACT_APP_EMAILJS_RECEIVER: receiverEmail,
      REACT_APP_EMAILJS_TEMPLATEID: template,
      REACT_APP_EMAILJS_USERID: user
    } = process.env

    sendFeedback(template,
      receiverEmail,
      user
    );
  }

  const sendFeedback = (templateId, receiverEmail, user) => {
    const feedback = `${fullnameContact} (${emailContact}): ${messageContact}`
    emailjs.send('mailgun', templateId, {emailContact, receiverEmail, feedback, fullnameContact}, user)
      .then((result) => {
          if(result.text == 'OK'){
            toast("Gracias por escribirnos! En cuanto podamos, nos ponemos en contacto contigo 🙂", {
              type: toast.TYPE.SUCCESS
            });          
          }
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
    <>
      <ExamplesNavbar />
      <LandingPageHeader />
      <ToastContainer />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">¿Qué es Helena Software?</h2>
                <h5 className="description">
                  Helena Software es un promesa realizada, un plan hecho en el pasado, un sueño 
                  convertido en realidad. Por ese motivo es que nos enfocamos en apoyar a todos 
                  aquellos que tienen un sueño y lo damos todo por ayudar a cumplirlo. Acompañamos
                  a los emprendedores, a todos aquellos que llegan con su idea y sus ojos brillando 
                  llenos de ilusión. Materializamos aquellas ideas, potenciamos sus negocios y 
                  los acompañamos a crecer.
                </h5>
                <br />
                {/**
                 * <Button
                  className="btn-round"
                  color="info"
                  href="#"
                  onClick={() => setVideoOpen(true)}
                >
                  Ver video
                </Button>
                 */}
                <Player 
                  open={videoOpen} 
                  toggleModal={() => setVideoOpen(!videoOpen)} 
                  url={""} />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-single-02" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Equipo</h4>
                    <p className="description">
                      Somos emprendedores, creativos e ingenieros que aplican sus conocimientos
                      al servicio de tu negocio. 
                    </p>
                    <Button className="btn-link" color="info" href="#equipo">
                      Ver equipo
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Nuevas ideas</h4>
                    <p>
                      ¿Tenes una idea y no sabes como llevarla a cabo? Es posible que podamos 
                      ayudarte. Contactanos y te asesoremos con gusto!
                    </p>
                    <Button className="btn-link" color="info" href="#contacto">
                      Contactar
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Control de tu negocio</h4>
                    <p>
                      ¿Tu negocio creció y no sabes como controlarlo? Tenemos herramientas de 
                      gestión para ayudarte a hacerlo. 
                    </p>
                    <Button className="btn-link" color="info" href="#">
                      Ver más
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-tap-01" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Desarrollo de software</h4>
                    <p>
                      Desarrollamos software a la medida de tus necesidades y bolsillo.
                      Aplicaciones web & móviles.
                    </p>
                    <Button className="btn-link" color="info" href="#">
                      Ver proyectos
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container id="equipo">
            <h2 className="title">Nuestro equipo</h2>
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/paula.png")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Paula Bourlot</CardTitle>
                        <h6 className="card-category">Fundadora</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/nacho.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Ignacio ramos</CardTitle>
                        <h6 className="card-category">Tecnología & Seguridad</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/joaquin.jpeg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Joaquin Perez</CardTitle>
                        <h6 className="card-category">Web Developer</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/luciano_b.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Luciano Britch</CardTitle>
                        <h6 className="card-category">Admin & Finanzas</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section landing-section">
          <Container id="contacto">
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">¿Querés escribirnos?</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Nombre completo</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Nombre completo" type="text" 
                          onChange={e => setFullnameContact(e.target.value)}/>
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" 
                          onChange={e => setEmailContact(e.target.value)}/>
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Mensaje</label>
                  <Input
                    placeholder="Contanos de tu idea, hacenos tu sugerencia, acercanos tu inquietud..."
                    type="textarea"
                    onChange={e => setMessageContact(e.target.value)}
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg" onClick={enviarEmail}>
                        Enviar mensaje
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default LandingPage;
